import Breadcrumbs from '@/components/Breadcrumbs';
import Button from '@/components/Button';
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import TreeWaterAndSun from '@/components/TreeWaterAndSun';
import { useTreeGuilds } from '@/lib/data/treeGuild';
import { uuid } from '@/lib/uuid';
import { navigate, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useMemo } from 'react';

const TreeGuilds: FC<PageProps> = () => {
  const treeGuildsData = useTreeGuilds();

  const grid = useMemo(() => {
    const bounds = treeGuildsData.guilds.reduce(
      (acc, it) => ({
        minX: Math.min(acc.minX, it.location.x),
        maxX: Math.max(acc.maxX, it.location.x),
        minY: Math.min(acc.minY, it.location.y),
        maxY: Math.max(acc.maxY, it.location.y),
      }),
      {
        minX: Infinity,
        maxX: -Infinity,
        minY: Infinity,
        maxY: -Infinity,
      }
    );
    if (bounds.minX === Infinity) {
      bounds.minX = 0;
      bounds.minY = 0;
      bounds.maxX = 0;
      bounds.maxY = 0;
    } else {
      bounds.minX -= 1;
      bounds.minY -= 1;
      bounds.maxX += 1;
      bounds.maxY += 1;
    }

    const addCards = treeGuildsData.guilds.reduce(
      (acc, it) => {
        acc[`${it.location.x},${it.location.y}`] = false;
        function setAddCard(dX: number, dY: number) {
          acc[`${it.location.x + dX},${it.location.y + dY}`] =
            acc[`${it.location.x + dX},${it.location.y + dY}`] !== false;
        }
        setAddCard(-1, -1);
        setAddCard(-1, 0);
        setAddCard(-1, 1);
        setAddCard(0, -1);
        setAddCard(0, 1);
        setAddCard(1, -1);
        setAddCard(1, -0);
        setAddCard(1, 1);
        return acc;
      },
      { '0,0': true }
    );

    return { bounds, addCards };
  }, [treeGuildsData.guilds]);

  const numberOfColumns = grid.bounds.maxX - grid.bounds.minX + 1;
  return (
    <Layout className="relative">
      <SEO url="/tree-guilds/" title="Tree Guilds" description="Tree Guilds" />

      <div className="container m-auto">
        <h1 className="text-3xl text-center font-bold w-full mt-10 mb-4">
          My Tree Guilds
        </h1>
        <Breadcrumbs links={[]} currentPage="My Tree Guilds" />
      </div>
      <div className="overflow-x-auto mt-10">
        <div
          className="grid gap-4 p-4 m-auto"
          style={{
            width: `calc(${250 * numberOfColumns}px + ${
              numberOfColumns + 1
            }rem)`,
            gridTemplateColumns: `repeat(${numberOfColumns}, 250px)`,
            gridTemplateRows: `repeat(${
              grid.bounds.maxY - grid.bounds.minY + 1
            }, 230px)`,
          }}
        >
          {treeGuildsData.guilds.map((guild) => (
            <div
              key={guild.id}
              className="col-span-1 row-span-1 shadow-lg rounded-md p-8 bg-white flex flex-col relative"
              style={{
                gridColumnStart: guild.location.x - grid.bounds.minX + 1,
                gridRowStart: guild.location.y - grid.bounds.minY + 1,
              }}
            >
              <div className="text-center text-7xl mb-4">🌳</div>
              <div className="text-center mb-4">{guild.tree}</div>
              <div className="flex space-x-5">
                <div>
                  <Button
                    color="danger"
                    onClick={() =>
                      confirm('Delete tree guild?') &&
                      treeGuildsData.deleteGuild(guild.id)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </Button>
                </div>
                <div className="flex-1">
                  <Button
                    onClick={() => navigate(`/tree-guild/?guildId=${guild.id}`)}
                  >
                    View Guild
                  </Button>
                </div>
              </div>
              <div className="absolute top-1 right-1">
                <TreeWaterAndSun tree={guild.tree} />
              </div>
            </div>
          ))}
          {Object.entries(grid.addCards)
            .filter(([, val]) => !!val)
            .map(([key]) => key.split(',').map((it) => parseInt(it, 10)))
            .map(([x, y]) => (
              <button
                key={`${x},${y}`}
                type="button"
                className="group col-span-1 row-span-1 shadow hover:shadow-lg rounded-md p-8 hover:bg-white flex items-center justify-center"
                style={{
                  gridColumnStart: x - grid.bounds.minX + 1,
                  gridRowStart: y - grid.bounds.minY + 1,
                }}
                onClick={() =>
                  navigate(`/tree-guild/?guildId=${uuid()}&x=${x}&y=${y}`)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-20 w-20 text-gray-400 group-hover:text-primary"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 4v16m8-8H4"
                  />
                </svg>
              </button>
            ))}
        </div>
      </div>
      <div className="container m-auto">
        <div className="mb-10" />
      </div>
    </Layout>
  );
};

export default TreeGuilds;
